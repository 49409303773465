@import url("../../utils/colors.css");
.sidebar-container {
  background-color: white;
  /* width: 20rem; */
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 10px;
  z-index: 2;
}
.sidebar-container2 {
  /* background-color: white; */
  width: 20rem;
  /* position: absolute; */
  top: 0;
  right: 0;
  height: 100%;
  padding: 10px;
  z-index: 2;
}

.sidebar-container .header-text {
  direction: rtl;
  height: 55px;
  justify-content: space-between;
}
.sidebar-container h1 {
  font-size: 24px;
  font-weight: bold;
}
.sidebar-container .profile-header {
  height: 60px;
  margin: 20px 0;
  background-color: #f5f5f5;
  border-radius: 6px;
}
.sidebar-container .profile-header img {
  width: 50px;
  height: 50px;
  margin: 4px;
}

.sidebar-container .profile-header .title-section h4 {
  /* margin: 0; */
}

.sidebar-container .profile-header .detail {
  white-space: normal;
  direction: rtl;
  font-size: 12px;
  margin: 2px 4px;
}

.sidebar-container .language-btn {
  direction: rtl;
  height: 40px;
  margin: 16px 0;
  border-radius: 6px;
  border: 1px solid #c2c2c2;
}

.sidebar-container .language-btn .urdu {
  width: 50%;
  text-align: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: white;
  background-color: var(--green-main-color);
}
.sidebar-container .language-btn .english {
  width: 50%;
  text-align: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
/*  for button enable and disable
.submit-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
} */

.sidebar-container .profile-list-section ul {
  padding: 0;
}
.sidebar-container .profile-list-section .list-items {
  width: 100%;
  direction: rtl;
  border-bottom: 1px solid #c2c2c2;
  margin: 8px 0;
  padding: 4px 0;
  position: relative;
}
.sidebar-container .profile-list-section .list-items p {
  font-size: 19px;
  padding-right: 8px;
  margin: 0;
}
.sidebar-container .profile-list-section .list-items .arrow-svg {
  position: absolute;
  left: 8px;
  top: 12px;
}
