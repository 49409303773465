@import url("../../utils/colors.css");

/** Header Start */
.crop-details-page .header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 14px 24px 14px;
}
.crop-details-page .header-section .header-content {
  display: flex;
  align-items: center;
}
.crop-details-page .header-section .header-content .left-side {
  direction: rtl;
  margin: 0px 18px 0px 0px;
}
.crop-details-page .header-section .header-content .left-side .title {
  margin: 0px;
  font-weight: 700;
  font-size: 18px;
}
.crop-details-page .header-section .header-content .left-side .sowing-date {
  margin-top: 12px;
  background-color: #def0fa;
  font-size: 14px;
  border-radius: 18px;
  padding: 10px 22px;
}
.crop-details-page .header-section .header-content .right-side img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
/** Header End */

/** Stepper Section Start */
.crop-details-page .stepper-container .stepper .step .step-number {
  margin: 0px 8px 0px 0px;
}
.crop-details-page .stepper-container .stepper .step .step-number .number,
.check {
  /* height: 32px; */
}
.crop-details-page .stepper-container .stepper .step .step-number .number  {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 1px solid var(--charcoal-color);
  background-color: var(--gray-color);
  color: var(--secondary-color);
  text-align: center;
  margin: 0px;
  vertical-align: middle;
  font-size: 14px;
  padding-top: 2px;
}

.crop-details-page .stepper-container .stepper .step .step-number .number-active  {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 1px solid var(--charcoal-color);
  background-color: var(--green-main-color);
  color: white;
  text-align: center;
  margin: 0px;
  vertical-align: middle;
  font-size: 14px;
  padding-top: 2px;
}

.crop-details-page .stepper-container .stepper .active-step .step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 4px 8px 4px;
  margin: 14px 8px 0px 0px;
  background-color: var(--green-main-color);
  color: var(--white-color);
  border-radius: 8px;
  width: 100px;
  height: 160px;
}
.crop-details-page
  .stepper-container
  .stepper
  .active-step
  .step-content
  .label {
  /* margin: 0px; */
  margin: 8px 0px 0px 0px;
  text-align: center;
  font-size: 14px;
}
.crop-details-page
  .stepper-container
  .stepper
  .active-step
  .step-content
  .description {
  margin: 0px;
  font-size: 12px;
  text-align: center;
}
.crop-details-page .stepper-container .stepper .in-active-step .step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 4px 8px 4px;
  margin: 14px 8px 0px 0px;
  background-color: var(--white-color);
  color: var(--black-color);
  border-radius: 8px;
  width: 100px;
  height: 160px;
}
.crop-details-page
  .stepper-container
  .stepper
  .in-active-step
  .step-content
  .label {
  /* margin: 0px; */
  margin: 8px 0px 0px 0px;
  text-align: center;
  font-size: 14px;
}
.crop-details-page
  .stepper-container
  .stepper
  .in-active-step
  .step-content
  .description {
  margin: 0px;
  font-size: 12px;
  text-align: center;
}
/** Stepper Section End */

/** Stage Content Section Start */
.crop-details-page .stage-content .stage-info {
  background-color: #faf6e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  direction: rtl;
  margin: 18px 14px;
  padding: 10px 12px;
}
.crop-details-page .stage-content .stage-info .description {
  margin: 0px 8px 0px 0px;
  color: var(--secondary-color);
  font-size: 12px;
}

/* Product Challenges Start */
.crop-details-page .stage-content .product-challenges {
  padding: 0px 12px;
}
.crop-details-page .stage-content .product-challenges .title {
  direction: rtl;
  font-size: 24px;
  font-weight: 700;
  color: var(--secondary-color);
  margin: 0px;
}
.crop-details-page
  .stage-content
  .product-challenges
  .cards-container
  .single-card {
  background-color: var(--white-color);
  border: 2px solid var(--gray-color);
  border-radius: 8px;
  padding: 12px 12px;
}
.crop-details-page .product-challenges .card-content .left-side {
  /* margin: 0px 14px 0px 0px; */
  width: 100%;
}
.crop-details-page .product-challenges .card-content .left-side .title {
  margin: 0px 0px 0px 0px;
  font-size: 18px;
  font-weight: 700;
  color: var(--secondary-color);
}
.crop-details-page .product-challenges .card-content .left-side .description {
  margin: 0px 0px 0px 0px;
  padding: 4px 4px 0 0;
  font-size: 14px;
  color: var(--charcoal-color);
  direction: rtl;
  line-height: 24px;
  /* width: 220px; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 30px;
  /* background-color: red */

}
.crop-details-page .product-challenges .card-content .right-side img {
  width: 76px;
  height: 76px;
  border-radius: 50%;
}
.crop-details-page .product-challenges .single-card .card-button {
  background-color: var(--secondary-color);
  color: var(--white-color);
  direction: rtl;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 14px 0px 0px 0px;
  padding: 8px 0px;
  border-radius: 8px;
}
.crop-details-page .product-challenges .single-card .card-button .btn-title {
  margin: 0px 0px 0px 12px;
}
/* Product Challenges End */
/** Stage Content Section End */
