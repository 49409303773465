/* Container for the logo to position it absolutely */
.logo-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px; /* Initial small size */
  height: 50px;
  /* background-color: gray; */
  animation: moveAndGrow 2s ease-in-out forwards;
}

/* Keyframes for the animation */
@keyframes moveAndGrow {
  0% {
    transform: translateX(-50%) translateY(2500%);
    width: 50px; /* Small initial size */
    height: 30px;
  }
  100% {
    transform: translateX(-50%) translateY(-900%);
    width: 200px; /* Final large size */
    height: 65px;
  }
}

/* Keyframes for the animation */
@keyframes fadeInImg {
    0% {
      opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
  }

/* Example for the logo image or text */
.logo-container img {
  width: 100%;
  height: 100%;
}

.fadeIn {
    animation: fadeInImg 1s ease-in-out forwards;
}
