@tailwind base;
@tailwind components;
@tailwind utilities;

/* 👇 Make sure the styles are applied in the correct order */
@layer mantine, mantine-datatable;

@font-face {
  font-family: 'CustomJaeelNooriFont';
  src: url('../public/font/Jameel Noori Nastaleeq Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}

*, body{
  /* font-family: "CustomJaeelNooriFont", serif; */
}
