@import url("../../utils/colors.css");

/* Navigation bar */
.navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  /* max-width: 320px; */
  display: flex;
  /* justify-content: space-around; */
  /* align-items: center; */
  height: 63px;
  background-color: var(--white-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.crops-active-tab,
.rates-active-tab,
.market-active-tab,
.videos-active-tab {
  background-color: var(--green-main-color);
}
.crops-inactive-tab,
.rates-inactive-tab,
.market-inactive-tab,
.videos-inactive-tab,
.tab-container .h4 {
  color: var(--charcoal-color);
}

.crops-tab,
.rates-tab,
.market-tab,
.videos-tab {
  width: 25%;
}
.crops-tab,
.rates-tab,
.market-tab,
.videos-tab,
.tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.crops-active-tab,
.rates-active-tab,
.market-active-tab,
.videos-active-tab h4 {
  color: var(--white-color);
}

.crops-tab,
.rates-tab,
.market-tab,
.videos-tab,
.tab-container .title {
  text-decoration: none;
  font-size: 14px;
  /* margin-top: 8px */
}
