:root {
  --yellow-main-color: #ffd705;
  --green-main-color: #007F3B;
  --blue-main-color: #3988FF;
  --secondary-color: #0f2532;
  --black-secondary-color: #0C0C0C;
  --charcoal-color: #777777;
  --white-color: #ffffff;
  --black-color: #000000;
  --light-green: #e3f9ef;
  --gray-color: #484848;
  --highlighted-green-color: #5dd363;
  --gray-color: #f5f5f5;
  --disabled-gray-color: #d3d3d3;
}
