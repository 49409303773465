/* Default styles for mobile screens */
.App {
  width: 100%;
  position: relative;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f4f4;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Main content */
.content {
  flex: 1;
}

.digit {
  font-family: "inter";
  font-size: inherit;
}